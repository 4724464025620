.invoice-details {
  margin: 20px 0px 20px 0;
  border-radius: 5px;
  box-shadow: 0 0 3.8px 0.2px rgba(0, 0, 0, 0.39);
  background-color: #fff;
}
.invoice-details-box-column-1 {
  /* margin: 15.5px 23px 10px 0;  */
  margin-left: 15px; 
  /* margin-right: 70px; */
  /* padding-right: 25px; */
  padding: 25px 34px 7px 25px;
  border-radius: 5px;
  box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #ecf1f4;
  text-align: left;
  font-size: 10px;
  /* padding-top: 15.5px; */
}






.order-details-container{
  height: 200px;
  margin-left: 30px; 
  margin-right: 20px;
  padding-right: 50px;
  border-radius: 5px;
  box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #ecf1f4;
  text-align: left;
  font-size: 10px;
}
.invoice-details-box-column-2 {
  width: 00px;
  border-radius: 5px;
  box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #ecf1f4;
  text-align: left;
  font-size: 10px;
  display: inline-flex;
  /* padding-top: 15.5px; */
}
.invoice-to-text{
  text-align: center;
  font-size: 18px;
  color: #0d3293;
  /* padding-top: 5px; */
  font-weight: bold;
  height: 23px;
}
.ship-to-text{
margin-left: 200px;
padding-left: 20px;
}
.ship-check-box{
padding-right: 100px;
display: inline;
align-items: flex;
padding-left: 35px;
}
.Rounded-Rectangle-1-copy-7 {
height: 47px;
  margin: 12px 0.5px 12px 23px;
  padding: 7px 115px 29.5px 6.5px;
  border-radius: 5px;
  box-shadow: 0 0 3.8px 0.2px rgba(0, 0, 0, 0.39);
  background-color: #fff;
}
.Rounded-Rectangle-3-copy-7 {
  width: 550px;
  height: 40.5px;
 margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 0 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #fff;
}
.bank-details{
    text-align: left;
}
.invoice-number-section {
  /* width: 1639px; */
  height: 100px;
  margin: 11px  ;
  padding: 12px 23.5px 8.5px 30.5px;
  border-radius: 5px;
  box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #ecf1f4;
  text-align: left;
}
.table-section {
  /* width: 1639px; */
  height: 300px;
  /* margin: 11px  ;
  padding: 12px 23.5px 8.5px 30.5px; */
  border-radius: 5px;
  box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #ecf1f4;
  text-align: left;
  display:inline-flex;
}

.bottom-section{
  margin-top: 50px;
}
.orderDetailsContainer{
  width: 45%;
}

.bank-details{
width: 25%;
height: 200.5px;
padding-top: 20px;
padding-left:30px ;
border-radius: 5px;
box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
background-color: #ecf1f4;
font-size: 12px;
line-height: 10px;
display: inline;
margin-left: 30px; 
}

.amountpayableContainer{
  width: 70%;
  height: 200.5px;
  padding-top: 20px;
  padding-left:30px ;
  border-radius: 5px;
  box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #ecf1f4;
  font-size: 12px;
  line-height: 10px;
  margin-left: 30px; 
  margin-top: 25px;
  }
  .signatureContainer{
    width: 23%;
  height: 200.5px;
  padding-top: 20px;
  padding-left:30px ;
  border-radius: 5px;
  box-shadow: 0px 2px 3.7px 0.3px rgba(68, 71, 72, 0.5);
  background-color: #ecf1f4;
  font-size: 12px;
  line-height: 10px;
  margin-left: 30px; 
  margin-top: 25px;
  }

.date-picker{
  height: 44px;
  padding: 12px 23.5px 8.5px 30.5px;
  text-align: left;
}
.fieldLabel{
  display: none;
}
.create-invoice-button{
  margin-left: 20px;
}
.taxable-table table tr:nth-child(odd){
  background: #8b96dc;
}
.taxable-table table tr:nth-child(even){
  background: #a3a9cd;
}
.taxable-table table tr:last-child{
  background: #0d3293;
  color: #ffffff;
}
.save_button{
  margin: 25px;
  text-align: right;
}

.print-header-nav{
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  /* box-shadow: inset 0 -2em 3em rgba(64, 64, 145, 0.1),
  0.3em 0.3em 1em rgba(65, 65, 165, 0.3); */
  box-shadow: 1px 2px 5px rgba(82, 172, 217, 0.69)
}

.TAX-INVOICE {
  /* margin: 8px 1099px 20px 345px; */
  font-family: Metropolis;
  font-size: 18px;
  font-weight: 600;
  line-height: 0px; 
  letter-spacing: normal;
  text-align: center;
  color: #101d3f;
}
.print-header{
  line-height: 0px;
  font-size: 10px;
  color: #101d3f;
}
.print-header1{
  line-height: 10px;
  font-size: 10px;
  color: #101d3f;
}



@media print {
  .form-control{
    background-color: #ecf1f4;
    border-color: #ecf1f4 !important;
    box-shadow: none;
    }
  p.bodyText {font-size:40px}
  .save_button{
    display: none;
  }
  .printble-text-view{
    width: auto !important
  }
  .create-invoice-button {
    display: none;
  }
  .table-container {
    overflow: hidden !important;
    height: auto !important;
    width: auto !important;
  }
  .date-picker{
    border: none !important;
    background: none !important;
    margin-top: 0px  !important;
    height: 30px !important;
  }
  .checkboc-hidden {
    display:  none;
  }
  .fieldLabel{
    display: block !important;
    font-size: 12px;
  }
  /* .orderDetailsContainer{
    width: 400px !important;
  } */
  
  .amountpayableContainer{
    width: 50%;
  }
  .signatureContainer {
    width: 35%;
  }
  .taxable-table{
    margin-left: 45px;
  }
  .order-details-container{
    display: block !important;
    height: 380px !important;
  }
}





.btn-pdf{
  width: 200px;
  height: 50px;
  margin-left: 900px;
  margin-bottom: 10px;
}
.css-zow5z4-MuiGrid-root>.MuiGrid-item {
  padding-top: 24px;
  margin-left: 140px;
}