@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* {
  margin: 0;
  padding: 0;
font-family: 'Poppins', sans-serif;;
}
/* home page */
.dashboard-container .Rounded-Rectangle-2-copy {
  margin-bottom: 20px;
}
.Rounded-Rectangle-2-copy {
  width: 100%;
  height: 280px;
  border-radius: 32.5px;
  box-shadow: 0px 4px 14.7px 1.3px rgba(0, 0, 0, 0.14);
  color: white;
  text-align: center;
}
.text-black {
  color:#000
}
.text-black:hover {
  color:#000
}
.more-info-above {
  padding: 25px 24px;
  height: 235px;
}
.more-info {
  padding: 10px 0px;
  background-color: #1591a5;
  border-bottom-left-radius: 32.5px;
  border-bottom-right-radius: 32.5px;
  height: 45px;
}
#bar-chart {
  min-height: 450px;
}
rect {
  width: 15px;
}
/* end */
/* order page */
.search-input-div {
  padding: 20px;
}
.search-input {
  width: 100%;
  border-radius: 26px;
  border: 1px solid #1e6ab2;
  padding: 1% 2%;
  background-image: url(../images/search-icon.png);
  background-size: contain;
  background-repeat: no-repeat;
  outline: 0;
  background-position: 99% 10px;
    background-size: 24px;
}
.nav-tabs {
  border: none;
    padding-top: 7px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.product-list {
  color:#0b3496;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
a:not(".order-id a") {
  color: #ada9a9;
}
.nav-tabs li a {
  margin-right: 20px;
  font-weight: 600;
  text-decoration: none;
  color: gray;
  font-size: 16px;
}
.filter-ui {
  margin-bottom: 20px;
  font-size: 18px;
}
span.tab-count {
  color: #ff3535;
}
.nav-tabs li.active a {
  color: #454bba;
}
/* end */
.zui-table {
  width: 100%;
  border: solid 1px #ddeeee;
  border-collapse: collapse;
  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}
.zui-table thead th {
  background-color: #edeef4;
  border: solid 1px #edeef4;
  color: #336b6b;
  padding: 10px;
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
}
.zui-table tbody td {
  border: solid 1px #ddeeee;
  color: #333;
  padding: 10px;
  text-shadow: 1px 1px 1px #fff;
}
.zui-table-rounded {
  border: none;
}
.zui-table-rounded thead th {
  background-color: #edeef4;
  border: none;
  text-shadow: 1px 1px 1px #ccc;
  color: #333;
  padding: 23px;
}
.zui-table-rounded thead th:first-child {
  border-radius: 10px 0 0 10px;
}
.zui-table-rounded thead th:last-child {
  border-radius: 0 10px 10px 0;
}
.zui-table-rounded tbody td {
  border: none;
  border-top: solid 1px #fff;
  background-color: #fff;
  padding: 23px;
}
.order-id {
  display: block;
  margin-bottom: 5px;
}
.zui-table-rounded tbody tr:last-child td:first-child {
  /* border-radius: 0 0 0 10px; */
}
.zui-table-rounded tbody tr:last-child td:last-child {
  /* border-radius: 0 0 10px 0; */
}
.action-option {
  float: right;
  margin-left: 9px;
}
.dropdown-toggle::after {
  content: none;
}
.dropdown-toggle svg {
  color: black;
  cursor: pointer;

}
p.search-tab {
  font-size: 24px;
  font-weight: bold;
  text-decoration: underline;
}
.dropdown-menu-down {
  border-radius: 21px;
  box-shadow: 0px 2px 14.7px 1.3px rgba(0, 0, 0, 0.36);
}
.dropdown-menu-down .dropdown-item {
  padding: 6px 16px;
}
.filter-checkbox {
  display: inline;
  width: 20px;
  margin-right: 1px;
}
.disable-color {
  color: #a7acaf;
}
.search-date {
  background: #d7d8e0;
  border: none;
  border-radius: 12px;
  padding: 5px 9px;
}
.ui-button {
  border: none;
  padding: 6px 42px;
  border-radius: 25px;
  box-shadow: 0px 4px 14.7px 1.3px rgb(0 0 0 / 14%);
  background-color: #fff;
  color: #056fe3;
}
.demand-request-div {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 0px 2.5px 25.7px 2.9px rgba(29, 90, 168, 0.14);
  background-color: #fff;
}
.demand-request-div > p {
  margin-bottom: 2px;
}
.left-text {
  color: #056fe3;
}
.right-text {
  color: black;
  font-weight: 500;
}
.demand-request-button-div {
  margin-top: 24px;
}
.demand-buton {
  color: #056fe3;
  padding: 6px 42px;
  border-radius: 5px;
  box-shadow: 0px 9.5px 27.4px 10.6px rgba(22, 72, 158, 0.18);
  background-color: #fff;
  border: none;
}
.view-button {
}
.cancel-button {
  background-color: #900c2e;
  color: white;
}
.primary-color {
  color: #056fe3;
}
.proposal-para {
  margin-bottom: 4px;
  font-weight: 500;
}
.accept-and-place-button {
  text-transform: uppercase;
  color: white;
  margin-top: 5px;
  text-align: center;
  padding: 10px 10px;
  background-color: #00b0f0;
  border: none;
  width: 100%;
}
.reorder-button,
.detail-button {
  width: 100%;
}
.detail-button {
  color: black;
  border: 1px solid black;
}
.request-for-logistic-button {
  margin: 20px 15px;
  width: 100%;
  background-color: #546bb5;
  color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.order-button {
  border: none;
  padding: 10px 30px;
  border-radius: 5px;
  box-shadow: 0px 0.5px 11.1px 2.4px rgba(22, 72, 158, 0.14);
  background-color: #fff;
}
.my-order-button {
  margin-right: 20px;
  color: #056fe3;
}
.my-received-button {
  color: #a7acaf;
}
.cat-img {
  max-height: 200px;
  border-radius: 10%;
  height: 170px;
  width: 100%;
}
.demand-bottom-div {
  text-align: center;
  background-color: #f2f5f7;
  padding: 14px 0px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.create-demand-button {
  padding: 5px 30px;
  background-color: #3e99c8;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: white;
  border: none;
  border-radius: 14px;
}
.demand-request-stripe {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 0px;
  border-radius: 17.5px;
  box-shadow: 0px 2.5px 25.7px 2.9px rgba(29, 90, 168, 0.14);
  background-color: #fff;
  margin-bottom: 40px;
}
/* directory page */
.directory-tab {
  font-size: 21px;
  text-transform: uppercase;
  color: gray;
}
.directory-tab-active {
  border-bottom: 1px solid black;
  font-weight: 600;
}
.directory-add-new-button {
  margin-right: 30px;
  padding: 7px 20px;
  box-shadow: 0px 4px 14.7px 1.3px rgba(0, 0, 0, 0.22);
  background-color: #fff;
  border: none;
}
.search-directory-div {
  padding: 12px 20px;
  border: 1px solid black;
}
.search-directory {
  border: none;
  width: calc(100% - 100px);
}
.directory-user-ui {
  border-left: 5px solid #6371a5;
  margin: 20px 0px;
  padding: 20px 30px 5px;
  border-radius: 5px;
  box-shadow: 0px 0.5px 14.7px 1.3px rgba(0, 0, 0, 0.17);
  background-color: #fff;
}
.user-avatar {
    width: 66px;
    float: left;
    border-radius: 50%;
    margin-right: 20px;
}

span.img-txt {
    float: left;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: blue;
    color: #fff;
    font-size: 30px;
    border-radius: 100%;
}
.user-option {
    left: -120px!important;
}
/* filter ui */
.behclick-panel  .list-group {
    margin-bottom: 0px;
}
.behclick-panel .list-group-item:first-child {
    border-top-left-radius:0px;
    border-top-right-radius:0px;
}
.behclick-panel .list-group-item {
    border-right:0px;
    border-left:0px;
}
.behclick-panel .list-group-item:last-child{
    border-bottom-right-radius:0px;
    border-bottom-left-radius:0px;
}
.behclick-panel .list-group-item {
    padding: 5px;
}
.behclick-panel .panel-heading {
    /* 				padding: 10px 15px;
                    border-bottom: 1px solid transparent; */
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top: 1px solid darkslategrey;
}
.behclick-panel .panel-heading:last-child{
    /* border-bottom: 0px; */
}
.behclick-panel {
    border-radius: 0px;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}
.behclick-panel .radio, .checkbox {
    margin: 0px;
    padding-left: 10px;
}
.panel-title {
    margin-left: 20px;
    margin-bottom: 3px;
}
.panel-title .fa-caret-down {
    position: absolute;
    right: 20px;
    width: 17px;
    height: 21px;
}
.behclick-panel .panel-title > a, .panel-title > small, .panel-title > .small, .panel-title > small > a, .panel-title > .small > a {
    outline: none;
}
.behclick-panel .panel-body > .panel-heading{
    padding:10px 10px;
}
.behclick-panel .panel-body {
    padding: 0px;
}
 /* unvisited link */
.behclick-panel a:link {
    text-decoration:none;
}

/* visited link */
.behclick-panel a:visited {
    text-decoration:none;
}

/* mouse over link */
.behclick-panel a:hover {
    text-decoration:none;
}

/* selected link */
.behclick-panel a:active {
    text-decoration:none;
}
.filter-option-left-ui {
    border: solid 1.5px #000;
    padding: 10px 0px 0px 0px;
}
.custom-breadcrum {
  text-align: left;
  width: 100%;
}

/* table custome design */
.table-responsive table {
  border-collapse: separate;
}
.table-responsive tbody td:first-child {
  border-left: 1px solid lightgray !important;
  background: #fff;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  margin-top: 12px !important;
  text-align: left;
}
.table-responsive tbody td:last-child {
  border-right: 1px solid lightgray !important;
  background: #fff;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  margin-top: 12px !important;
  text-align: left;
}
.table-responsive tbody td{
  border-bottom: 1px solid lightgray !important;
  border-top: 1px solid lightgray !important;
  background: #fff;
  margin-top: 12px !important;
  text-align: left;
}

.user-detail {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding-left: 12px;
}
.user-detail h5 {
  font-weight: bold;
  font-size: 23px;
}
.user-detail small {
  color: #9ea8c7;
  font-size: 12px;
  margin-top: -10px;
  font-weight: bold;
}

.user-detail p a {
  color: #1351a4 !important;
  font-size: 15px;
  display: block;
  padding-top: 11px;
}
/* end */


.modal-footer button.btn.btn-secondary {
  background: #880329;
  font-size: 22px;
  border-radius: 15px;
  padding: 10px 30px;
}

.modal-footer button.btn.btn-secondary.btn-sm {
  font-size: 14px;
  padding: 5px 15px;
}

.modal-footer button.btn.btn-primary {
  background: #014285;
  font-size: 22px;
  border-radius: 15px;
  padding: 10px 30px;
}

.modal-footer button.btn.btn-primary.btn-sm{
  font-size: 14px;
  padding: 5px 15px;
}

.modal-footer {
  justify-content: center !important;
}

/* .form-control {
  border-color: #4198f0!important;
  box-shadow: 0 0 10px #0573e38a;
} */

.loading-screen {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #494949d6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-box {
  z-index: 5000 !important;
  position: fixed !important;
}

.file-container {

  border: 2px dashed #0e3293;
  border-radius: 5px;
  margin: 15px 0;
}
.file-container label {
  width: 100%;
  max-width: 100% !important;
  border: none;
  text-align: left;
}

a.nav-link {
  
  background: linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background: -webkit-linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  -webkit-linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal-title {
  background: linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background: -webkit-linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  -webkit-linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background-clip: text;
  -webkit-text-fill-color: transparent;}

.header-color th {
  background: linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background: -webkit-linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  -webkit-linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.file-container h3 {
  margin-bottom: 15px; 
  background: linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background: -webkit-linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  -webkit-linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.img-show {
  display: flex;
  max-width: 100%;
  padding: 10px;
  justify-content: start;
  flex-wrap: wrap;
}

.img-box {
  position: relative;
  width: 150px;
  margin-left: 20px;

}
button.remove-img {
  position: absolute;
  top: -10px;
  right: -10px;
  border: none;
  background: red;
  color: #fff;
  padding: 0 6px;
  border-radius: 10px;
}

.input-container-pro {
  display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background: #fff;
    box-shadow: 0 0 10px #c6c4ee;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    text-align: left;
}
.input-container-pro label, .input-container-pro p {
  background: linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background: -webkit-linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  -webkit-linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;

}
.input-box.form-control {
  border-color: #fff !important;
  box-shadow: 0 0 0;
}
.add-pack-btn {
  background: #fff;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0 10px #c6c4ee;
  border: none;
  width: 100%;
  padding: 10px 0;
  margin: 0 0 10px;
}
.submit-button-pro-1{
  background: linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background: -webkit-linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  -webkit-linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  box-shadow: 0 0 10px lightgray;
  border: 0px;
  width: 100%;
  padding: 10px 0;
}

.demand-close.btn-secondary {
  background: #880329;
  font-size: 14px;
  box-shadow: 0 0 10px lightgray;
  border: 0px;
  width: 100%;
  padding: 10px 0;
  color: #fff;
}
.submit-button-pro {
  border-radius: 17.5px;
  background-image: linear-gradient(to top, #8799c7, #3e99c8, #0e3293);
  color: #fff;
  width: 100%;
  padding: 10px 0;
  border: none;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 24px;
}




/* slider toggle  */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.has-error {
  border: 1px solid red;
}
span.error {
  color: red;
  font-size: 9px;
}


.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 200px;
}
.upload-icon {
  width: 50px;
  height: 50px;
  background: url(../images/upload.png) no-repeat center center; 
  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  background: linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background: -webkit-linear-gradient(to top, #8799c7, #3e99c8, #0e3293), 
  -webkit-linear-gradient(to bottom, #9c9cb0, #9c9cb0);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  width: 100%;

}


.file-display-container {
  position: relative;
  width: 100%;
}

.file-status-bar{
  width: 100%;
  vertical-align:top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block!important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align:top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background-size: 100%;
  position: absolute;
}

.file-size {
  display:inline-block;
  vertical-align:top;
  color:#30693D;
  margin-left:10px;
  margin-right:5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.drop-message input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 180px;
  width: 100%;
  opacity: 0;
}

.file-remove  {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}

.page-title {
  text-transform: uppercase;
  font-size:20px;
}

ul.order-option-list {
  list-style: none;
}

ul.order-option-list li {
  margin-bottom: 6px;
}

@media(max-width:1180px){
  .Rounded-Rectangle-2-copy {
    height: 330px;
  }
  .more-info-above {
    height: 285px;
  }
}

.demand-tabs a {
  background: #fff;
  box-shadow: 0 0 10px lightblue;
  padding: 10px 20px;
  border-radius: 20px;
}