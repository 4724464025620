body {
    font-family: unset;
}
.full-height {
    height: 100vh;
}
.login-dark {
    height: 100vh;
    background-size: cover;
    position: relative;
}
.mobile-img {
    width: 20px;
    height:30px;
    margin-right: 10px;
    margin-top: 15px;
}
.login-button {
    padding: 0px 30px;
}
.login-dark .form {
    max-width: 500px;
    width: 90%;
    background-color: #fff;
    padding: 20px 80px;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}

.login-dark .illustration {
    text-align: center;
    padding: 10px 0 20px;
    font-size: 100px;
    color: #2980ef;
}

.login-dark .form .form-control {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
}

.login-dark .form .btn-primary {
    background: #214a80;
    border: none;
    border-radius: 4px;
    padding: 11px;
    box-shadow: none;
    margin-top: 26px;
    text-shadow: none;
    outline: none;
}

.login-dark .form .btn-primary:hover,
.login-dark .form .btn-primary:active {
    background: #214a80;
    outline: none;
}

.login-dark .form .forgot {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #6f7a85;
    opacity: 0.9;
    text-decoration: none;
}

.login-dark .form .forgot:hover,
.login-dark .form .forgot:active {
    opacity: 1;
    text-decoration: none;
}

.login-dark .form .btn-primary:active {
    transform: translateY(1px);
}

.logo-img {
    max-width: 140px;
}

.login-txt-color {
    color: #3b96c6;
}

.login-bottom {
    width: 100%;
    padding: 15px 30px;
    border-radius: 37.5px;
    background-color: #4299c8;
    border: none;
    color: white;
}

.pwd-bottom {
    width: 100%;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 37.5px;
    background-color: white;
    border-color: #4299c8;
    color: #4299c8;
    letter-spacing: 1;
}

.input-container {
    display: -ms-flexbox;
    /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 25px;
}

.input-field {
    width: 100%;
    padding: 10px;
    outline: none;
    border: none;
    border-bottom: 2px solid #4299c8;
}