  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(231, 224, 224);
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(160, 192, 237);
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #549cf5;
  }

.reports-header{
    padding-top: 10px;
    padding-bottom: 10px;
    background-image: linear-gradient(to top, #101d3f, #1d42a4, #7c95d5);
}
.reports-table-head{
    text-transform:uppercase ;
    color: #565e7d;
    font-size: 22px;
    font-weight: 600;
}
.view-reports-table-tr{
    padding-top: 25px;
    padding-bottom: 25px;
    border: none;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: 0px 2px 15px 5px rgb(216, 232, 245);
    /* box-shadow: 0 0 10px #c6c4ee; */
    color: #565e7d;
    font-family: "MetropolisNF", sans-serif;
}
