::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(231, 224, 224);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(160, 192, 237);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #549cf5;
}

.pointer {
  cursor: pointer;
}

.Home-Finance-Records {
  width: 713px;
  font-family: "MetropolisNF", sans-serif;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a7acaf;
}
.Finance-Records {
  width: 1183px;
  font-family: "MetropolisNF", sans-serif;
  line-height: 10px;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565e7d;
}

.-records-found {
  width: 620px;
  font-family: "MetropolisNF", sans-serif;
  font-size: 28px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #a7acaf;
}

.rectangle-vertical-rounded {
  width: 50px;
  padding: 30px 10px 30px 10px;
  height: 100px;
  object-fit: contain;
  border-radius: 15px;
  background-image: linear-gradient(to top, #3e98c8, #c2e9fb);
}

.rectangle-vertical-rounded-second {
  width: 50px;
  padding: 30px 10px 30px 10px;
  height: 100px;
  object-fit: contain;
  border-radius: 15px;
  background-image: linear-gradient(to top, #d09292, #c82270);
}

.Rounded-Rectangle-2 {
  width: 50px;
  height: 8px;
  padding: 0 342px 0 2px;
  border-radius: 10px;
  background-color: #c9dbe5;
}

.Recent-Invoices {
  font-family: "MetropolisNF", sans-serif;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565e7d;
}

.Invoice135 {
  font-family: "MetropolisNF", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.pdf-date {
  font-family: "MetropolisNF", sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565e7d;
}

.Create-Invoice {
  object-fit: contain;
  font-family: "MetropolisNF", sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565e7d;
}
.Vendors-list {
  font-family: "MetropolisNF", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e98c8;
}
.Add-Vendor {
  font-family: "MetropolisNF", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565e7d;
}

.Customers-list {
  font-family: "MetropolisNF", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ca3776;
}

.venders-list-card {
  border-radius: 15px;
}
.venders-list-card-small{
  display: flex;
  align-items: center;
  padding-top: 15px;
   padding-bottom: 15px;
}
.card-small{
  border-radius: 20px;
}

.text-style-number {
  color: #5673e7;
}
.names {
  font-family: "MetropolisNF", sans-serif;
  font-size: 9px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565e7d;
}

.name {
  font-family: "MetropolisNF", sans-serif;
  font-size: 10px;
  padding-top: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565e7d;
}
.png-icons {
  height: 20px;
  width: 20px;
}
.png-icons-text {
  font-size: 10px;
}
.png-icons-text-Add {
  font-size: 10px;
}

.container1 {
  background: #fff;
  margin: 0 20px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.container .topic {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content .list {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-right: 50px;
  position: relative;
}
.content .list label {
  font-size: 22px;
  font-weight: 500;
  line-height: 60px;
  cursor: pointer;
  padding-left: 25px;
  transition: all 0.5s ease;
  color: #333;
  z-index: 12;
}
#home:checked ~ .list label.home,
#blog:checked ~ .list label.blog,
#help:checked ~ .list label.help,
#code:checked ~ .list label.code,
#about:checked ~ .list label.about {
  color: rgb(14, 6, 6);
}
.content .list label:hover {
  color: #6d50e2;
}
.content .slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  border-radius: 12px;
  background: #6d50e2;
  transition: all 0.4s ease;
}
#home:checked ~ .list .slider {
  top: 0;
}
#blog:checked ~ .list .slider {
  top: 60px;
}
#help:checked ~ .list .slider {
  top: 120px;
}
#code:checked ~ .list .slider {
  top: 180px;
}
#about:checked ~ .list .slider {
  top: 240px;
}
.content .text-content {
  width: 80%;
  height: 100%;
}
.content .text {
  display: none;
}
.content .text .title {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: 500;
}
.content .text p {
  text-align: justify;
}
.content .text-content .home {
  display: block;
}
#home:checked ~ .text-content .home,
#blog:checked ~ .text-content .blog,
#help:checked ~ .text-content .help,
#code:checked ~ .text-content .code,
#about:checked ~ .text-content .about {
  display: block;
}
#blog:checked ~ .text-content .home,
#help:checked ~ .text-content .home,
#code:checked ~ .text-content .home,
#about:checked ~ .text-content .home {
  display: none;
}
.content input {
  display: none;
}

.btn-collected {
  /* border: 1px solid rgb(243, 237, 237); */
  border-radius: 15px;
  width: 204px;
  
}
.btn-collected-selected {
  border: 1px solid rgb(203, 192, 255);
  color: white;
  background-image: linear-gradient(to top, #101d3f, #1d42a4, #7c95d5);
  border-radius: 15px;
  width: 204px;
}
.btn-collected-border {
  border: 1px solid #1d42a4;
  border-radius: 25px;
   padding: 0px;
}
.btn-save {
  background-color: #ffffff;
  box-shadow: 0 0 58.8px 11.2px rgba(30, 64, 155, 0.19);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.add-entry-card {
  background-color: #f9fbfd;
}

.btn-add-vendor{
  background-color: #1a3e98;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 2px;
  border-radius: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  font-family: "MetropolisNF", sans-serif;
}
.btn-add-Customer{
  background-color: #1a3e98;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 2px;
  border-radius: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: white;
  font-family: "MetropolisNF", sans-serif;
}

.bio-labels{
  font-size: 14px;
}

.bio-heads{
  text-decoration: underline;
  font-weight: 600;
  color: #1a3e98;
}
.bio-labels-p{
  font-size: 12px;
}
.goods-headers{
  font-size: 10px;
}

.rates{
  font-size: 8px;
  letter-spacing: -1px;
 }
 .amount-spacing{
  letter-spacing: 0px;
 }
 .goods-row{
  box-shadow: 0 0 58.8px 11.2px rgba(164, 182, 231, 0.19);
 }